import { customStorageEventName } from '@vueuse/core'

// Patch Storage (localStorage & sessionStorage) to emit change events to the same window where they are made
// This makes it possible to communicate between microfrontends using useLocalStorage and useSessionStorage from vueuse

Storage.prototype.setItem = new Proxy(Storage.prototype.setItem, {
  apply(target, thisArg, argumentList) {
    const event = new CustomEvent(customStorageEventName, {
      detail: {
        key: argumentList[0],
        oldValue: thisArg.getItem(argumentList[0]),
        newValue: argumentList[1],
        storageArea: thisArg,
      },
    })
    window.dispatchEvent(event)
    return Reflect.apply(target, thisArg, argumentList)
  },
})

Storage.prototype.removeItem = new Proxy(Storage.prototype.removeItem, {
  apply(target, thisArg, argumentList) {
    const event = new CustomEvent(customStorageEventName, {
      detail: {
        key: argumentList[0],
        oldValue: thisArg.getItem(argumentList[0]),
        newValue: null,
        storageArea: thisArg,
      },
    })
    window.dispatchEvent(event)
    return Reflect.apply(target, thisArg, argumentList)
  },
})

Storage.prototype.clear = new Proxy(Storage.prototype.clear, {
  apply(target, thisArg, argumentList) {
    const event = new CustomEvent(customStorageEventName, {
      detail: {
        key: null,
        storageArea: thisArg,
      },
    })
    window.dispatchEvent(event)
    return Reflect.apply(target, thisArg, argumentList)
  },
})
